<template>
    <div>
        <!-- CRUD Modal -->
        <b-modal
            id="cru_modal"
            :title="`${
                modals.cru_modal.resource.id == null ? 'Create' : 'Update'
            } Resource`"
            size="sm"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
        >
            <div>
                <b-row>
                    <b-col>
                        <validation-observer
                            v-slot="{ handleSubmit }"
                            ref="formValidator"
                        >
                            <b-form
                                role="form"
                                @submit.prevent="handleSubmit(submit_form)"
                            >
                                <input
                                    type="submit"
                                    ref="ru_modal_form_submit_button"
                                    hidden
                                />
                                <b-row>
                                    <b-col>
                                        <base-input
                                            label="Name"
                                            class="mb-3"
                                            :placeholder="
                                                modals.cru_modal.resource.name
                                                    ? modals.cru_modal.resource
                                                          .name
                                                    : 'Name'
                                            "
                                            name="Name"
                                            :rules="{ required: true }"
                                            v-model="
                                                modals.cru_modal.resource.name
                                            "
                                        >
                                        </base-input>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <base-input
                                            label="URL"
                                            class="mb-3"
                                            :placeholder="
                                                modals.cru_modal.resource.url
                                                    ? modals.cru_modal.resource
                                                          .url
                                                    : 'URL'
                                            "
                                            name="URL"
                                            :rules="{ required: true }"
                                            v-model="
                                                modals.cru_modal.resource.url
                                            "
                                        >
                                        </base-input>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <base-input
                                            label="Description"
                                            class="mb-3"
                                            :placeholder="
                                                modals.cru_modal.resource
                                                    .description
                                                    ? modals.cru_modal.resource
                                                          .description
                                                    : 'Description'
                                            "
                                            name="Description"
                                            :rules="{ required: true }"
                                            type="textarea"
                                            v-model="
                                                modals.cru_modal.resource
                                                    .description
                                            "
                                        >
                                        </base-input>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="text-right">
                        <base-button
                            type="danger"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="$bvModal.hide('cru_modal')"
                            :disabled="modals.cru_modal.loading"
                            >Close
                        </base-button>
                        <base-button
                            type="success"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="cru_modal_save(modals.cru_modal.resource)"
                            :disabled="modals.cru_modal.loading"
                            :loading="modals.cru_modal.loading"
                            :success="modals.cru_modal.success"
                            >Save
                        </base-button>
                    </b-col>
                </b-row>
            </div>
        </b-modal>

        <!-- Delete Modal -->
        <b-modal
            id="delete_modal"
            title="Confirm"
            size="sm"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
        >
            <div class="text-center">
                <h1>Are you sure?</h1>
                <p>
                    You are about to delete the resource:<br />
                    <strong>
                        "{{ modals.delete_confirmation_modal.resource.name }}"
                    </strong>
                </p>
                <base-button
                    type="danger"
                    :pill="true"
                    class="my-4 btn-success"
                    :loading="modals.delete_confirmation_modal.loading"
                    :disabled="modals.delete_confirmation_modal.loading"
                    @click="
                        delete_resource(
                            modals.delete_confirmation_modal.resource
                        )
                    "
                    >Delete
                </base-button>
                <base-button
                    type="primary"
                    :pill="true"
                    class="my-4 btn-success"
                    @click="$bvModal.hide('delete_modal')"
                    :disabled="modals.delete_confirmation_modal.loading"
                    >Cancel
                </base-button>
            </div>
        </b-modal>

        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="6">
                        <h1>Hackathon Resources</h1>
                    </b-col>
                    <b-col cols="6" class="text-right">
                        <el-tooltip
                            content="Add"
                            placement="top"
                            v-if="is_hackathon_manager"
                        >
                            <base-button
                                type="primary"
                                round
                                icon
                                size="sm"
                                @click="cru_modal_show()"
                            >
                                <span class="btn-inner"
                                    ><i class="fa fa-plus"></i
                                ></span>
                            </base-button>
                        </el-tooltip>
                    </b-col>
                </b-row>

                <div v-if="$apollo.loading && table_data.length == 0">
                    <b-row
                        class="mt-2 d-flex align-items-center justify-content-center"
                    >
                        <b-col
                            class="d-flex align-items-center justify-content-center"
                        >
                            <Transition>
                                <h1>
                                    <i class="fas fa-spinner fa-spin ml-1"></i>
                                </h1>
                            </Transition>
                        </b-col>
                    </b-row>
                </div>
                <b-row v-else>
                    <b-col>
                        <el-table
                            class="table-responsive align-items-center table-flush no-lines"
                            header-row-class-name="thead-light"
                            row-class-name="table-rows"
                            :data="table_data"
                            :empty-text="`No resources yet${
                                is_hackathon_manager || is_hackathon_creator
                                    ? ', use the \'+\' button to add a resource.'
                                    : '...'
                            }`"
                        >
                            <el-table-column
                                label="Name"
                                min-width="240px"
                                sortable
                            >
                                <template v-slot="{ row }">
                                    <a
                                        v-if="row.url"
                                        class="mb-0"
                                        :href="row.url"
                                        target="_blank"
                                        >{{ row.name }}</a
                                    >
                                    <a v-else class="mb-0">{{ row.name }}</a>
                                </template>
                            </el-table-column>

                            <el-table-column
                                label="Description"
                                min-width="240px"
                                sortable
                            >
                                <template v-slot="{ row }">
                                    <p class="mb-0">
                                        {{ row.description }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column
                                min-width="180px"
                                align="center"
                                v-if="is_hackathon_manager"
                            >
                                <div slot-scope="{ row }" class="table-actions">
                                    <el-tooltip content="Edit" placement="top">
                                        <a
                                            href="#!"
                                            @click.prevent="cru_modal_show(row)"
                                            class="table-action"
                                            data-toggle="tooltip"
                                            data-original-title="Update Judge"
                                        >
                                            <i class="fas fa-edit"></i>
                                        </a>
                                    </el-tooltip>
                                    <el-tooltip
                                        content="Delete"
                                        placement="top"
                                    >
                                        <a
                                            href="#!"
                                            @click.prevent="
                                                delete_confirmation_modal_show(
                                                    row
                                                )
                                            "
                                            class="table-action table-action-delete"
                                            data-toggle="tooltip"
                                            data-original-title="Delete"
                                        >
                                            <i class="fas fa-trash"></i>
                                        </a>
                                    </el-tooltip>
                                </div>
                            </el-table-column>
                        </el-table>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
// Modules
import {
    Table,
    TableColumn,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Tooltip,
} from "element-ui";

// Queries
import { GET_HACKATHON_RESOURCES } from "@/graphql/queries";

// Mutations
import {
    CREATE_HACKATHON_RESOURCE,
    UPDATE_HACKATHON_RESOURCE,
    DELETE_HACKATHON_RESOURCE,
} from "@/graphql/mutations";

export default {
    name: "HackthonCrudResourcesTable",
    components: {
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
    },
    props: {
        hackathon_id: {
            type: String,
            description: "The Id of the hackathon.",
            default: null,
        },
    },
    apollo: {
        get_hackathon_resources: {
            query: GET_HACKATHON_RESOURCES,
            result(res) {
                let flattened = graph_utils.flattened_response(res.data);
                this.table_data = [];
                if (flattened.allHackathonResourcesResource.length > 0) {
                    flattened.allHackathonResourcesResource.forEach((el) => {
                        let resource = {
                            id: el.id,
                            name: el.name,
                            description: el.description,
                            url: el.url,
                        };

                        this.table_data.push(resource);
                    });
                }

                this.is_hackathon_creator =
                    res.data.hackathonHackathon.isCreator;
                this.is_hackathon_manager =
                    res.data.hackathonHackathon.isManager;
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    hackathon_id: this.hackathon_id,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_resources = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon_resources: null,
            },
            table_data: [], // All the judges table data is in here.

            modals: {
                cru_modal: {
                    loading: false,
                    success: false,
                    resource: {
                        id: null,
                        name: null,
                        description: null,
                        url: null,
                    },
                },
                delete_confirmation_modal: {
                    loading: false,
                    resource: {
                        id: null,
                        name: null,
                        description: null,
                        url: null,
                    },
                },
            },
            is_hackathon_manager: false,
            is_hackathon_creator: false,
        };
    },
    methods: {
        // Modals

        //cru_modal
        cru_modal_show(resource) {
            if (this.is_hackathon_manager) {
                this.$bvModal.show("cru_modal");
                if (resource) {
                    this.modals.cru_modal.resource = resource;
                } else {
                    this.modals.cru_modal.resource = {
                        id: null,
                        name: null,
                        description: null,
                        url: null,
                    };
                }
            }
        },
        cru_modal_save(resource) {
            // Saving existing
            this.modals.cru_modal.loading = true;
            let mutation = CREATE_HACKATHON_RESOURCE;
            let variables = {
                hackathon_id: this.hackathon_id,
                name: resource.name,
                description: resource.description,
                url: resource.url,
            };
            if (resource.id) {
                mutation = UPDATE_HACKATHON_RESOURCE;
                variables.id = resource.id;
            }
            this.$apollo
                .mutate({
                    mutation: mutation,
                    variables: variables,
                })
                .then(() => {
                    this.modals.cru_modal.loading = false;

                    this.modals.cru_modal.success = true;
                    setTimeout(() => {
                        this.modals.cru_modal.success = false;
                    }, 1000);
                    setTimeout(() => {
                        this.$bvModal.hide("cru_modal");
                    }, 800);
                    this.$notify({
                        message: `Successfully ${
                            resource.id == null ? "created" : "updated"
                        } resource: ${resource.name}`,
                        timeout: 3000,
                        icon: "ni ni-check-bold",
                        type: "success",
                    });
                    this.$apollo.queries.get_hackathon_resources.refetch();
                })
                .catch((err) => {
                    console.log(err);
                    this.$notify({
                        message: `Could not ${
                            resource.id == null ? "create" : "update"
                        } resource: ${resource.name}`,
                        timeout: 5000,
                        icon: "ni ni-fat-remove",
                        type: "warning",
                    });
                    this.modals.cru_modal.loading = false;
                });
        },

        // delete_modal
        delete_confirmation_modal_show(resource) {
            this.$bvModal.show("delete_modal");
            if (resource) {
                this.modals.delete_confirmation_modal.resource = resource;
            } else {
                this.modals.delete_confirmation_modal.resource = {
                    id: null,
                    name: null,
                    description: null,
                    url: null,
                };
            }
        },
        delete_resource(resource) {
            this.modals.delete_confirmation_modal.loading = true;
            this.$apollo
                .mutate({
                    mutation: DELETE_HACKATHON_RESOURCE,
                    variables: {
                        resource_id: resource.id,
                    },
                })
                .then(() => {
                    this.$notify({
                        message: `Successfully deleted resource: ${resource.name}`,
                        timeout: 3000,
                        icon: "ni ni-check-bold",
                        type: "success",
                    });
                    this.modals.delete_confirmation_modal.loading = false;
                    this.$bvModal.hide("delete_modal");
                    this.$apollo.queries.get_hackathon_resources.refetch();
                })
                .catch((err) => {
                    console.log(err);
                    this.$notify({
                        message: `Could not delete resource: ${resource.name}`,
                        timeout: 5000,
                        icon: "ni ni-fat-remove",
                        type: "warning",
                    });
                    this.modals.delete_confirmation_modal.loading = false;
                });
        },
    },
    watch: {
        hackathon_id() {
            if (this.hackathon_id) {
                graph_utils.enable_query(
                    this.$apollo.queries.get_hackathon_resources
                );
            } else {
                graph_utils.disable_query(
                    this.$apollo.queries.get_hackathon_resources
                );
            }
        },
    },
    mounted() {
        if (!this.$apollo.queries.get_hackathon_resources.skip) {
            this.$apollo.queries.get_hackathon_resources.refetch();
        }
        // setting this query's fetch policy
        graph_utils.set_fetch_cache_and_network(
            this.$apollo.queries.get_hackathon_resources
        );
    },
};
</script>

<style></style>
